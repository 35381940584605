import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Link,
  Paper,
  Typography,
} from '@mui/material'
import {
  ShopifyCustomAttribute,
  ShopifyLineItem,
  ShopifyLineItemConnection,
  ShopifyOrder,
  ShopifyMoneyBag,
  ShopifyDiscountApplication,
} from 'lib/types.ts'
import _ from 'lodash'
import { formatFormalDateTime, usdFormatter } from 'pages/ReportsApp/utils.tsx'
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium'
import CropOriginalIcon from '@mui/icons-material/CropOriginal'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import { useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import { stores } from 'config/cache.ts'
import { useLocation, useNavigate } from 'react-router-dom'

export type OrderDetailsInput = {
  order: Partial<ShopifyOrder> & {}
  storeId?: string
}

const OrderSummaryLine = ({
  item,
  title,
  discountApplications,
}: {
  title: string
  item?: ShopifyMoneyBag
  discountApplications?: ShopifyDiscountApplication
}) => {
  if (!item) return null
  const parsedItemValue = parseFloat(item.shopMoney.amount)
  if (parsedItemValue === 0) return null
  const discountValue = discountApplications?.edges?.[0]?.node?.value

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        paddingTop: '5px',
      }}
    >
      <Box sx={{ flexBasis: '33%' }}>{title}</Box>
      <Box sx={{ flexBasis: '33%' }}>
        {discountValue?.__typename === 'PricingPercentageValue'
          ? `${discountValue.percentage}% Discount`
          : `${usdFormatter.format(parseFloat(discountValue?.amount || '0'))}`}
      </Box>
      <Box sx={{ marginLeft: 'auto' }}>-{usdFormatter.format(parsedItemValue)}</Box>
    </Box>
  )
}

const OrderHeaderLeft = ({ order }: OrderDetailsInput) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1px',
        alignItems: 'left',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '16px',
          alignItems: 'center',
        }}
      >
        <div>
          <Typography variant={'h6'} fontWeight={800}>
            Order {order?.name}
          </Typography>
        </div>
        <div>
          <Chip
            label={_.capitalize(order.displayFinancialStatus)}
            sx={{ minWidth: '70px', fontWeight: 600 }}
            size={'small'}
          />
        </div>
        <div>
          <Chip
            label={_.capitalize(order.displayFulfillmentStatus)}
            sx={{ minWidth: '70px', fontWeight: 600 }}
            size={'small'}
          />
        </div>
      </Box>
      <Box>
        <Typography variant={'body2'}>Placed on {formatFormalDateTime(order.createdAt)}</Typography>
      </Box>
    </Box>
  )
}

const OrderHeaderRight = ({ order }: OrderDetailsInput) => {
  return <Box></Box>
}

const OrderDetailsHeader = ({ order }: OrderDetailsInput) => {
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          gap: '16px',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            justifyContent: 'space-between',
            flexDirection: 'row',
            width: '100%',
            gap: '6px',
          }}
        >
          <Box
            sx={{
              alignItems: 'start',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Button
              onClick={() => navigate('/vendor/reports/orders')}
              sx={{ paddingTop: '4px', minWidth: '32px' }}
            >
              <KeyboardDoubleArrowLeftIcon />
            </Button>
            <OrderHeaderLeft order={order} />
          </Box>
        </Box>
        <Box>
          <OrderHeaderRight order={order} />
        </Box>
      </Box>
      <Box sx={{ display: { xs: 'flex', sm: 'none' }, flexDirection: 'column', gap: '6px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'right' }}>
          <OrderHeaderRight order={order} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'start' }}>
          <Button
            onClick={() => navigate('/vendor/reports/orders')}
            sx={{ paddingTop: '4px', minWidth: '32px' }}
          >
            <KeyboardDoubleArrowLeftIcon />
          </Button>
          <OrderHeaderLeft order={order} />
        </Box>
      </Box>
    </Box>
  )
}

const Channel = ({ storeId }: { storeId: string }) => {
  const storesVar = useReactiveVar(stores)

  return (
    <>
      <Paper
        elevation={5}
        sx={{ display: 'flex', flexDirection: 'column', gap: '18px', p: 2, borderRadius: '16px' }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
          <Typography variant={'body1'} fontWeight={700}>
            Channel
          </Typography>
          {storesVar?.find((store) => store.id === storeId)?.name}
        </Box>
      </Paper>
    </>
  )
}

const Customer = ({ order }: OrderDetailsInput) => {
  return (
    <>
      <Paper
        elevation={5}
        sx={{ display: 'flex', flexDirection: 'column', gap: '18px', p: 2, borderRadius: '16px' }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
          <Typography variant={'body1'} fontWeight={700}>
            Customer
          </Typography>
          {order.customer?.displayName}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
          <Typography variant={'body1'} fontWeight={700}>
            Contact information
          </Typography>
          {/*<Typography variant={'body1'} fontWeight={500}>*/}
          <Link href={`mailto:${order.customer?.email}`}>{order.customer?.email}</Link>
          {/*</Typography>*/}
          {/*<Typography variant={'body1'} fontWeight={500}>*/}
          {order.customer?.phone}
          {/*</Typography>*/}
        </Box>
        {order.shippingAddress && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
            <Typography variant={'body1'} fontWeight={700}>
              Shipping address
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0px' }}>
              <div>{order.shippingAddress?.name}</div>
              <div>{order.shippingAddress?.company}</div>
              <div>{order.shippingAddress?.address1}</div>
              {order.shippingAddress?.address2 && <div>{order.shippingAddress?.address2}</div>}
              <div>
                {order.shippingAddress?.city}, {order.shippingAddress?.provinceCode}{' '}
                {order?.shippingAddress?.zip}
              </div>
              <div>{order.shippingAddress?.phone}</div>
            </Box>
          </Box>
        )}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
          <Typography variant={'body1'} fontWeight={700}>
            Billing address
          </Typography>
          {order.billingAddressMatchesShippingAddress ? (
            'Same as shipping address'
          ) : (
            <>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0px' }}>
                <div>{order.billingAddress?.name}</div>
                <div>{order.billingAddress?.company}</div>
                <div>{order.billingAddress?.address1} </div>
                {order.billingAddress?.address2 && <div>{order.billingAddress?.address2}</div>}
                <div>
                  {order.billingAddress?.city}, {order.billingAddress?.provinceCode}{' '}
                  {order.billingAddress?.zip}
                </div>
                <div>{order.billingAddress?.phone}</div>
              </Box>
            </>
          )}
        </Box>
      </Paper>
    </>
  )
}

export const LineItems = ({ order }: OrderDetailsInput) => {
  const [openDialog, setOpenDialog] = useState<string>()

  const columns: GridColDef[] = [
    {
      field: 'title',
      headerName: 'Product',
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '2px',
              width: '100%',
            }}
          >
            <div style={{ fontWeight: 'bold' }}>{params.row.title}</div>
            {params.row.customAttributes?.find((a: ShopifyCustomAttribute) => a.key === 'Product Name') && (
              <Box sx={{ paddingLeft: '20px', display: 'flex', alignItems: 'center' }}>
                <Box sx={{ alignSelf: 'center' }}>
                  <Button
                    size={'small'}
                    sx={{ minWidth: '16px' }}
                    onClick={() =>
                      setOpenDialog(
                        params.row.customAttributes?.find(
                          (a: ShopifyCustomAttribute) => a.key === '__instanceImage3DUrl'
                        )?.value
                      )
                    }
                  >
                    <CropOriginalIcon />
                  </Button>
                </Box>
                <Box>
                  Private Labeled as{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {
                      params.row.customAttributes?.find(
                        (a: ShopifyCustomAttribute) => a.key === 'Product Name'
                      )?.value
                    }
                  </span>{' '}
                </Box>
              </Box>
            )}
          </Box>
        )
      },
      flex: 1,
      resizable: false,
      sortable: false,
    },
    {
      field: 'sku',
      headerName: 'SKU',
      width: 130,
      headerAlign: 'left',
      align: 'left',
      type: 'text',
      resizable: false,
      sortable: false,
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      width: 75,
      headerAlign: 'right',
      align: 'right',
      type: 'number',
      resizable: false,
      sortable: false,
    },
    {
      field: 'total',
      headerName: 'Total',
      width: 100,
      headerAlign: 'right',
      align: 'right',
      type: 'number',
      resizable: false,
      sortable: false,
      valueGetter: (params) => params.row.discountedTotalSet?.shopMoney.amount,
      valueFormatter: ({ value }) => {
        if (!value) return null
        return usdFormatter.format(value)
      },
    },
  ]

  return (
    <>
      <Dialog maxWidth={'lg'} fullWidth={true} open={!!openDialog} onClose={() => setOpenDialog(undefined)}>
        <Box sx={{ width: 'fit-content', backgroundColor: '#efefef' }}>
          <DialogContent sx={{ margin: 0, padding: 1 }}>
            <Paper elevation={5}>
              <img src={openDialog} width={'100%'} />
            </Paper>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(undefined)}>Close</Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Box sx={{ p: 2, borderRadius: '16px', width: '100%' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'grid' }}>
            <DataGridPremium
              sx={{
                borderLeftWidth: 0,
                borderRightWidth: 0,
                borderTopWidth: 0,
              }}
              rowHeight={60}
              autoHeight={true}
              showCellVerticalBorder={false}
              showColumnVerticalBorder={false}
              disableColumnResize={true}
              disableColumnMenu={true}
              columns={columns}
              rows={
                (order?.lineItems as ShopifyLineItemConnection)?.edges?.map(
                  ({ node }: { node: ShopifyLineItem }, index: number) => ({
                    ...node,
                    id: index,
                  })
                ) || []
              }
              hideFooterRowCount={true}
              hideFooter={true}
            />
          </Box>
          <Box sx={{ paddingLeft: '10px', paddingRight: '10px' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'left',
                paddingTop: '5px',
              }}
            >
              <Box sx={{ flexBasis: '33%' }}>Subtotal</Box>
              <Box sx={{ flexBasis: '33%' }}>{order.subtotalLineItemsQuantity} items</Box>
              <Box sx={{ marginLeft: 'auto' }}>
                {usdFormatter.format(
                  _.sum(
                    (order?.lineItems as ShopifyLineItemConnection)?.edges?.map(
                      ({ node }: { node: ShopifyLineItem }) =>
                        parseFloat(node.discountedTotalSet?.shopMoney.amount || '0')
                    )
                  )
                )}
              </Box>
            </Box>
            <OrderSummaryLine
              title='Discount'
              item={order.totalDiscountsSet}
              discountApplications={order.discountApplications}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'left',
                paddingTop: '5px',
              }}
            >
              <Box sx={{ flexBasis: '33%' }}>
                Shipping {order.shippingLine && `(${order.shippingLine?.title})`}
              </Box>
              <Box sx={{ flexBasis: '33%' }}></Box>
              <Box sx={{ marginLeft: 'auto' }}>
                {order.shippingLine?.discountedPriceSet &&
                  usdFormatter.format(parseFloat(order.shippingLine?.discountedPriceSet?.shopMoney.amount))}
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingTop: '10px',
              }}
            >
              <div>
                <Typography variant={'h6'}>Total</Typography>
              </div>
              <div>
                <Typography variant={'h6'}>
                  {order.totalPriceSet &&
                    usdFormatter.format(parseFloat(order.totalPriceSet?.shopMoney.amount))}
                </Typography>
              </div>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

const Payments = ({ order }: OrderDetailsInput) => {
  return (
    <>
      <Paper
        elevation={5}
        sx={{ display: 'flex', flexDirection: 'column', gap: '18px', p: 2, borderRadius: '16px' }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
          <Typography variant={'body1'} fontWeight={700}>
            Payments
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
            {order.transactions?.map((tx, index) => {
              return (
                <Box key={index} sx={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                  <div>
                    <img src={tx.paymentIcon?.url} />
                  </div>
                  <div>
                    {usdFormatter.format(parseFloat(tx.amountSet.shopMoney.amount || '0'))}
                    {' - '}
                    {tx.paymentDetails.company} {tx.paymentDetails.number}&nbsp;. Expiration{' '}
                    {tx.paymentDetails.expirationMonth}/{tx.paymentDetails.expirationYear}.
                    <Box sx={{ color: tx.status === 'SUCCESS' ? 'black' : 'red' }}>
                      {_.capitalize(tx.status)} on {formatFormalDateTime(tx.processedAt)}{' '}
                    </Box>
                  </div>
                </Box>
              )
            })}
          </Box>
        </Box>
      </Paper>
    </>
  )
}

export const OrderDetails = ({ order, storeId }: OrderDetailsInput) => {
  if (!order) {
    return <></>
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
        }}
      >
        <OrderDetailsHeader order={order} />

        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: { xs: '16px', lg: '32px' },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: { md: 2 / 3 },
              gap: { xs: '16px', lg: '32px' },
            }}
          >
            <LineItems order={order} />
            <Payments order={order} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: { md: 1 / 3 },
              gap: { xs: '16px', lg: '32px' },
            }}
          >
            {storeId && <Channel storeId={storeId} />}
            <Customer order={order} />
          </Box>
        </Box>
      </Box>
    </>
  )
}
