import { gql } from '@apollo/client'

export const MIGRATE_CUSTOMER_LABELS_FROM_SHOPIFY = gql`
  mutation migrateCustomerLabelsFromShopify($shopifyStoreConfigurationId: String!, $customerId: String!) {
    migrateCustomerLabelsFromShopify(
      shopifyStoreConfigurationId: $shopifyStoreConfigurationId
      customerId: $customerId
    ) {
      success
      instanceId
      error
      details
    }
  }
`
