import { currentUser } from '@/store/user'
import { useRecoilValue } from 'recoil'
import { ROLES } from 'lib/rbac'

const useIsAdmin = () => {
  const user = useRecoilValue(currentUser)

  const isAdmin = user?.role === ROLES.ADMIN

  return isAdmin
}

export default useIsAdmin
