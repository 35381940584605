import { useState } from 'react'
import { useQuery, useLazyQuery } from '@apollo/client'

import { PROXY_SHOPIFY_QUERY } from '@/lib/graphql/proxyShopifyQuery'
import { ShopifyOrder } from '@/lib/types'
import { CustomerDetailProps } from './CustomerOrderCard.types'

export const useCustomerOrderCardData = ({ selectedStoreIdVar, customer }: CustomerDetailProps) => {
  const [data, setData] = useState<Partial<ShopifyOrder>>()

  const [lazyShopifyQuery, { loading: loadingShopify }] = useLazyQuery(PROXY_SHOPIFY_QUERY)
  const customerNumberId = customer.id.replace('gid://shopify/Customer/', '');

  useQuery(PROXY_SHOPIFY_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      query: 'orders',
      shopifyStoreId: selectedStoreIdVar,
      variables: JSON.stringify({
        reverse: true,
        first: 1,
        sortKey: 'CREATED_AT',
        query: `customer_id:${customerNumberId}`,
      }),
    },
    onCompleted: (data) => {
      const order = JSON.parse(data.proxyShopifyQuery || '{}')?.data?.orders?.edges?.[0]?.node
      if (!order) setData(undefined)
      lazyShopifyQuery({
        fetchPolicy: 'no-cache',
        variables: {
          query: 'order',
          shopifyStoreId: selectedStoreIdVar,
          variables: JSON.stringify({
            id: order.id,
          }),
        },
        onCompleted: (data) => {
          setData(JSON.parse(data.proxyShopifyQuery || '{}')?.data?.order)
        },
      })
    },
  })

  return { data, loadingShopify }
}
