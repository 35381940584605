import { gql } from '@apollo/client'

export const PROCESS_SHIPMENT = gql`
  mutation processShipment(
    $orderNumber: String!
    $trackingNumber: String!
    $shipmentDate: String!
    $organizationId: String!
  ) {
    processShipment(
      orderNumber: $orderNumber
      trackingNumber: $trackingNumber
      shipmentDate: $shipmentDate
      organizationId: $organizationId
    )
  }
`
