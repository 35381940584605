import { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { useReactiveVar, useLazyQuery, useQuery } from '@apollo/client'

import { PROXY_SHOPIFY_QUERY } from 'lib/graphql/proxyShopifyQuery.ts'
import { apolloClient } from 'lib/AppSyncProvider'
import { stores } from 'config/cache.ts'
import { PageInfo, ShopifyStoreConfiguration } from 'lib/graphql/types.ts'
import { LIST_SHOPIFY_STORE_CONFIGURATIONS } from 'lib/graphql/listShopifyStoreConfigurations.ts'
import { ORDERS_LIST } from 'lib/graphql/listOrdersWorkflow.ts'

type StoreId = string
type Filter = string

const PAGE_SIZE_ORDERS = 100

type PageInfoMap = {
  [key: string]: PageInfo
}

export const useStoreData = (selectedOrganizationIdVar?: string) => {
  const [orgStores, setOrgStores] = useState<string[]>([])

  const { loading: loadingStores } = useQuery(LIST_SHOPIFY_STORE_CONFIGURATIONS, {
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        organizationId: { equals: selectedOrganizationIdVar },
      },
      take: 100,
    },
    skip: !selectedOrganizationIdVar,
    onCompleted: (data) => {
      setOrgStores(data?.listShopifyStoreConfigurations.map((x: ShopifyStoreConfiguration) => x.id))
    },
  })

  return { orgStores, loadingStores }
}

export const useOrdersData = ({ orgStores, filterQuery }: { orgStores: StoreId[]; filterQuery: Filter }) => {
  const storesVar = useReactiveVar(stores)

  const [lazyShopifyQuery, { loading: loadingShopify }] = useLazyQuery(PROXY_SHOPIFY_QUERY)

  const results = orgStores?.map((storeId) => {
    return apolloClient.readQuery({
      query: PROXY_SHOPIFY_QUERY,
      // Provide any required variables in this object.
      // Variables of mismatched types will return `null`.
      variables: {
        query: 'orders',
        shopifyStoreId: storeId,
        variables: JSON.stringify({
          first: 100,
          reverse: true,
          query: filterQuery,
        }),
      },
    })
  })

  const pageInfoByStore: PageInfoMap = useMemo(
    () =>
      orgStores.reduce(
        (prevValue, storeId, index) => ({
          ...prevValue,
          [storeId]: results?.[index]?.proxyShopifyQuery?.orders?.pageInfo,
        }),
        {}
      ) || {},
    [orgStores, results]
  )

  useEffect(() => {
    if (orgStores.length === 0) return
    if (!filterQuery) return
    if (!orgStores) return
    orgStores.forEach((storeId) => {
      if (!pageInfoByStore?.[storeId] || pageInfoByStore?.[storeId]?.hasNextPage) {
        lazyShopifyQuery({
          fetchPolicy: 'cache-and-network',
          notifyOnNetworkStatusChange: true,
          variables: {
            query: 'orders',
            shopifyStoreId: storeId,
            variables: JSON.stringify({
              first: PAGE_SIZE_ORDERS,
              reverse: true,
              query: filterQuery,
              after: pageInfoByStore?.[storeId]?.endCursor,
            }),
          },
        })
      }
    })
  }, [orgStores, pageInfoByStore, lazyShopifyQuery, filterQuery])

  const resultsByStore: any = orgStores?.reduce(
    (prevValue, storeId, index) => ({
      ...prevValue,
      [storeId]: results?.[index]?.proxyShopifyQuery.orders?.data,
    }),
    {}
  )

  const resultsOrders = resultsByStore
    ? _.flatten(
        Object.keys(resultsByStore).map((storeId) => {
          return (resultsByStore[storeId] || []).map((order: any) => ({
            ...order,
            id: order.id,
            storeId: storeId,
            storeName: storesVar?.find((x) => x.id === storeId)?.name,
          }))
        })
      )
    : []

  return { resultsOrders, loadingShopify }
}

export const useOrderWorkflowData = ({
  storeId,
  ordersCommonId,
}: {
  storeId: string
  ordersCommonId: string[]
}) => {
  const {
    loading: loadingOrders,
    data: orderWorkflowData,
    error: errorOrders,
    fetchMore,
  } = useQuery(ORDERS_LIST, {
    variables: {
      offset: 0,
      limit: ordersCommonId.length,
      where: {
        storeId: { equals: storeId },
        commonId: { in: ordersCommonId },
      },
    },
    skip: !storeId,
  })

  return { loadingOrders, orderWorkflowData, errorOrders, fetchMore }
}
