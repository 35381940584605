import { Box, LinearProgress, Paper, Typography } from '@mui/material'

import { LineItems } from 'pages/ReportsApp/OrderDetails.tsx'
import { formatFormalDateTime } from 'pages/ReportsApp/utils.tsx'
import { CustomerDetailProps } from './CustomerOrderCard.types'
import { useCustomerOrderCardData } from './CustomerOrderCard.hooks'

export const CustomerOrderCard = ({ customer, selectedStoreIdVar }: CustomerDetailProps) => {
  const { data, loadingShopify } = useCustomerOrderCardData({ customer, selectedStoreIdVar })

  if (loadingShopify) {
    return <LinearProgress />
  }

  return (
    <Box>
      <Paper elevation={10} sx={{ p: 2, position: 'relative', borderRadius: '15px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Box>
            <Typography variant={'h5'}>Most recent order</Typography>
          </Box>
          {data && (
            <Box>
              <Typography variant={'body1'}>
                {data.name} ordered on {formatFormalDateTime(data.createdAt)}
              </Typography>
            </Box>
          )}
        </Box>

        <Box sx={{ paddingTop: '10px', width: '100%' }}>
          {!data ? (
            <Typography variant={'body1'}>This customer hasn’t placed any orders yet</Typography>
          ) : (
            <>
              <LineItems order={data} />
            </>
          )}
        </Box>
      </Paper>
    </Box>
  )
}
