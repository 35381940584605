import React, { useState } from 'react'
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
} from '@mui/material'
import { withMask } from 'use-mask-input'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useMutation } from '@apollo/client'
import * as Yup from 'yup'
import { Input } from 'components/Forms/Input.tsx'
import { FormTextField } from 'components/Forms/FormTextField.tsx'
import { yupResolver } from '@hookform/resolvers/yup'
import { ShopifyCustomer } from 'lib/types.ts'
import { PROXY_SHOPIFY_MUTATION } from 'lib/graphql/proxyShopifyMutation.js'

import { COUNTRIES } from '@/constants/countries'

export type AddressFieldProps = {
  id?: string
  address1?: string
  address2?: string | null
  phone?: string | undefined | null
  company?: string | null
  city?: string
  provinceCode?: string
  zip?: string
  countryCodeV2?: string
}

const validationSchema = Yup.object().shape({
  id: Yup.string().optional(),
  address1: Yup.string().required('Address is required'),
  address2: Yup.string().nullable(),
  company: Yup.string().nullable().optional(),
  city: Yup.string().required('City is required'),
  provinceCode: Yup.string().required('State is required'),
  zip: Yup.string().required('Postal Code is required'),
  phone: Yup.string().nullable().optional(),
  countryCodeV2: Yup.string()
    .oneOf(
      COUNTRIES.map((x) => x.code),
      `Country must be on of: ${COUNTRIES.map((x) => x.label).join(', ')}.`
    )
    .required('Country is required'),
})

interface CustomerCreateOrUpdateProps {
  storeId?: string
  address?: AddressFieldProps
  onSuccess: () => void
  closeDialog: () => void
  customer: ShopifyCustomer & { metafields: any; original: any; approved: boolean }
  mode: 'update' | 'create'
}

const initialValues = {
  id: '',
  address1: '',
  company: '',
  city: '',
  provinceCode: '',
  zip: '',
  phone: '',
  countryCodeV2: 'US',
}

export const UpdateAddressDialog: React.FC<CustomerCreateOrUpdateProps> = ({
  address,
  storeId,
  onSuccess,
  closeDialog,
  customer,
  mode,
}) => {
  const [submitError, setSubmitError] = useState<string>()

  const [updateCustomer] = useMutation(PROXY_SHOPIFY_MUTATION, {
    async onCompleted() {
      await onSuccess()
      closeDialog()
    },
    onError(error) {
      setSubmitError(error.message)
      // setSubmitError(JSON.parse(error.message).map((e: any) => e.message))
    },
  })

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty, isValid },
  } = useForm<AddressFieldProps>({
    mode: 'all',
    criteriaMode: 'all',
    resolver: yupResolver<AddressFieldProps>(validationSchema),
    defaultValues: mode === 'create' ? initialValues : address,
  })

  const graphqlDataInput = (values: AddressFieldProps) => {
    const updatedAddress = {
      ...values,
      id: values.id || undefined,
      phone: values.phone || '',
      countryCode: values.countryCodeV2 || 'US',
      countryCodeV2: values.countryCodeV2 || 'US',
      country: values.countryCodeV2 || 'US',
    }

    const addresses = (customer.addresses || [])
      .filter((adr) => adr.id !== updatedAddress.id)
      .map((adr) => ({
        ...adr,
        countryCode: adr.countryCodeV2 || 'US',
        phone: adr.phone || '',
      }))

    return {
      id: customer.id,
      addresses: [...addresses, updatedAddress],
    }
  }

  const update = async (values: AddressFieldProps) => {
    await updateCustomer({
      variables: {
        mutation: 'updateCustomer',
        shopifyStoreId: storeId,
        variables: JSON.stringify(graphqlDataInput(values)),
      },
    })
  }

  const onSubmit: SubmitHandler<AddressFieldProps> = async (data) => {
    setSubmitError('')
    await update(data)
  }

  return (
    <>
      <DialogTitle>{mode === 'create' ? 'Add Address' : 'Update Address'} </DialogTitle>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isSubmitting}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input type='hidden' control={control} name='id' />
        <DialogContent sx={{ minWidth: '600px' }}>
          <Box>{submitError && <Alert severity={'error'}>{submitError}</Alert>}</Box>
          <Stack spacing={2}>
            <FormTextField
              name='company'
              label='Business Name'
              type='text'
              fullWidth
              variant='standard'
              disabled={false}
              control={control}
              rules={{ required: false }}
            />
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
              <FormTextField
                name='address1'
                label='Address'
                type='text'
                fullWidth
                variant='standard'
                disabled={false}
                control={control}
                rules={{ required: true }}
              />
              <FormTextField
                name='address2'
                label='Apartment, suite, etc'
                type='text'
                fullWidth
                variant='standard'
                disabled={false}
                control={control}
                rules={{ required: false }}
              />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
              <FormTextField
                name='city'
                label='City'
                type='text'
                fullWidth
                variant='standard'
                disabled={false}
                control={control}
                rules={{ required: true }}
              />
              <Box sx={{ width: '120px' }}>
                <FormTextField
                  name='provinceCode'
                  label='State'
                  type='text'
                  fullWidth
                  variant='standard'
                  disabled={false}
                  control={control}
                  rules={{ required: true }}
                  mask={withMask('AA', {
                    placeholder: ' ',
                  })}
                />
              </Box>
              <Box sx={{ width: '240px' }}>
                <FormTextField
                  name='zip'
                  label='Postal Code'
                  type='text'
                  fullWidth
                  variant='standard'
                  disabled={false}
                  control={control}
                  rules={{ required: true }}
                />
              </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
              <FormTextField
                select
                label='Country'
                name='countryCodeV2'
                required
                control={control}
                fullWidth
                disabled={mode === 'update'}
              >
                {COUNTRIES.map((country) => (
                  <MenuItem key={country.code} value={country.code}>
                    {country.label}
                  </MenuItem>
                ))}
              </FormTextField>
              <FormTextField
                name='phone'
                label='Telephone'
                type='text'
                fullWidth
                variant='standard'
                disabled={false}
                control={control}
                mask={withMask('+1 (999) 999-9999', {
                  placeholder: ' ',
                })}
              />
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ pt: 3 }}>
          <Button
            disabled={isSubmitting}
            onClick={() => {
              closeDialog()
            }}
          >
            Cancel
          </Button>
          <Button type='submit' disabled={isSubmitting || !(isDirty && isValid)}>
            {mode === 'create' ? 'Save' : 'Update'}
          </Button>
        </DialogActions>
      </form>
    </>
  )
}
