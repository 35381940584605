import { useState } from 'react'
import dayjs from 'dayjs'
import { useReactiveVar, useMutation } from '@apollo/client'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import {
  Box,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Dialog,
  TextField,
  Button,
  DialogContent,
} from '@mui/material'

import { PROCESS_SHIPMENT } from '@/lib/graphql/processShipment'
import { organizationId } from 'config/cache'

export default function OrderDialog({ open, setOpen }: { open: boolean; setOpen: (b: boolean) => void }) {
  const [orderNumber, setOrderNumber] = useState('')
  const [trackingNumber, setTrackingNumber] = useState('')
  const [shipmentDate, setShipmentDate] = useState<dayjs.Dayjs | null>(null)
  const organizationIdVar = useReactiveVar(organizationId)

  const [processShipment] = useMutation(PROCESS_SHIPMENT)

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: async (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault()
          await processShipment({
            variables: {
              orderNumber,
              trackingNumber,
              shipmentDate: shipmentDate?.toISOString(),
              organizationId: organizationIdVar,
            },
          })
          handleClose()
        },
      }}
    >
      <DialogTitle>Process Shipment</DialogTitle>
      <DialogContent>
        <DialogContentText>Enter the Order number so we can Process your Shipment again</DialogContentText>
        <TextField
          autoFocus
          required
          value={orderNumber}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setOrderNumber(event.target.value)
          }}
          margin='dense'
          id='name'
          name='order'
          label='Order number'
          type='text'
          fullWidth
          variant='standard'
        />
        <Box display='flex' alignItems='center'>
          <TextField
            autoFocus
            required
            sx={{ flexBasis: '50%', marginRight: 2 }}
            value={trackingNumber}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setTrackingNumber(event.target.value)
            }}
            margin='dense'
            id='tracking'
            name='trackingNumber'
            label='Tracking number'
            type='text'
            fullWidth
            variant='standard'
          />
          <DesktopDatePicker
            sx={{ flexBasis: '50%', marginTop: 1, marginBottom: 0.5 }}
            label='Shipment Date'
            value={shipmentDate || null}
            slotProps={{
              textField: { variant: 'standard' },
              openPickerButton: { color: 'primary' },
            }}
            onChange={(newValue) => {
              setShipmentDate(newValue)
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button type='submit' disabled={!orderNumber || !trackingNumber || !shipmentDate}>
          Process
        </Button>
      </DialogActions>
    </Dialog>
  )
}
