import { useReactiveVar } from '@apollo/client'
import { Box, Divider, Tooltip, Typography } from '@mui/material'
import {
  DataGridPremium,
  GridColDef,
  GridRowSelectionModel,
  GridToolbarContainer,
  GridToolbarExport,
} from '@mui/x-data-grid-premium'
import { useEffect, useState } from 'react'
import { GridCsvExportOptions } from '@mui/x-data-grid-pro'
import EmailIcon from '@mui/icons-material/MailOutline'
import CancelIcon from '@mui/icons-material/Cancel'
import { Link, useLocation } from 'react-router-dom'

import ReportFilters, { ReportFiltersType } from 'pages/ReportsApp/ReportFilters.tsx'
import { organizationId, selectedOrganization } from 'config/cache.ts'
import { apolloClient } from 'lib/AppSyncProvider'

import {
  adjudicateWmsStatus,
  currentDateTime,
  formatDate,
  formatDateTime,
  formatTime,
  renderFulfillments,
  renderOrderFinancialStatus,
  renderOrderFulfillmentStatus,
  renderOrderWmsStatus,
  usdFormatter,
} from 'pages/ReportsApp/utils'
import { useOrdersData, useOrderWorkflowData, useStoreData } from './OrdersReport.hooks'
import { EnrichedOrders } from 'pages/ReportsApp/OrdersReport/OrdersReport.types'

export const OrdersReport = () => {
  const selectedOrganizationIdVar = useReactiveVar(organizationId)
  const organizationVar = useReactiveVar(selectedOrganization)
  const [rowSelectionModel] = useState<GridRowSelectionModel>([])
  const [, setSelectedOrder] = useState<EnrichedOrders>()
  const [reportFilters, setReportFilters] = useState<ReportFiltersType>()

  const location = useLocation()

  const { orgStores, loadingStores } = useStoreData(selectedOrganizationIdVar)

  const generateQuery = (filters: ReportFiltersType) => {
    if (!filters?.dateFilter.to || !filters?.dateFilter.from) {
      return ''
    } else {
      return `created_at:>='${filters.dateFilter.from.format(
        'YYYY-MM-DD'
      )}' created_at:<='${filters.dateFilter.to.format('YYYY-MM-DD[T23:59:59Z]')}'`
    }
  }

  const { resultsOrders, loadingShopify } = useOrdersData({
    orgStores,
    filterQuery: generateQuery(reportFilters!),
  })

  const storeId = resultsOrders[0]?.storeId
  const ordersCommonId = resultsOrders.map((order) => order.name)

  const { orderWorkflowData } = useOrderWorkflowData({ storeId, ordersCommonId })

  const mergedData = resultsOrders.map((order) => ({
    ...order,
    displayWmsStatus: adjudicateWmsStatus(
      orderWorkflowData?.listOrdersWorkflow.find((x: { commonId: string }) => x.commonId === order.name)
        ?.orderWorkflow
    ),
  }))

  useEffect(() => {
    if (!rowSelectionModel.length) {
      setSelectedOrder(undefined)
    } else {
      const order = resultsOrders.find((order) => order.id === rowSelectionModel[0])
      setSelectedOrder(order)
    }
  }, [rowSelectionModel])

  const handleRunReport = (v: ReportFiltersType) => {
    setReportFilters(v)
    apolloClient.cache.modify({
      fields: {
        proxyShopifyQuery() {
          return undefined // This will clean all orders that had been previously fetched
        },
      },
      broadcast: false,
    })
  }

  const columns: GridColDef[] = [
    {
      field: 'storeName',
      headerName: 'Store',
      minWidth: 90,
      flex: 0.2,
    },
    {
      field: 'name',
      headerName: 'Order',
      minWidth: 90,
      flex: 0,
      renderCell: (params) => {
        const storeId = params.row.storeId
        const orderId = params.row.id?.replace('gid://shopify/Order/', '')
        return (
          <>
            <Link to={`${location.pathname}/${storeId}/${orderId}`}>{params.row.name}</Link>
          </>
        )
      },
    },
    {
      field: 'createdAtDate',
      headerName: 'Order Date',
      valueGetter: (params) => {
        return formatDate(params.row.createdAt)
      },
      renderCell: (params) => {
        return formatDate(params.row.createdAt)
      },
      flex: 0,
      minWidth: 90,
    },
    {
      field: 'createdAtTime',
      headerName: 'Order Time',
      valueGetter: (params) => {
        return formatTime(params.row.createdAt)
      },
      renderCell: (params) => {
        return formatTime(params.row.createdAt)
      },
      flex: 0,
      minWidth: 90,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0,
      minWidth: 90,
      aggregable: false,
      valueGetter: (params) => {
        return params.row.cancelledAt ? 'Cancelled' : 'Live'
      },
      renderCell: (params) => {
        if ((params.id as string).match(/footer/)) {
          return ''
        }
        return params.row.cancelledAt ? (
          <Tooltip title={'Cancelled'}>
            <CancelIcon sx={{ color: '#8B0000' }} />
          </Tooltip>
        ) : (
          <>
            {renderOrderFinancialStatus(params.row.displayFinancialStatus)}
            {renderOrderWmsStatus(params.row.displayWmsStatus, params.row.displayFinancialStatus)}
            {renderOrderFulfillmentStatus(params.row.displayFulfillmentStatus)}
          </>
        )
      },
    },
    {
      field: 'cancelledAt',
      headerName: 'Cancelled At',
      valueGetter: (params) => {
        return formatDateTime(params.row.cancelledAt)
      },
    },
    {
      field: 'displayFinancialStatus',
      headerName: 'Payment',
    },
    {
      field: 'displayFulfillmentStatus',
      headerName: 'Shipping',
    },
    {
      field: 'displayName',
      headerName: 'Customer',
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        return params.row.customer?.displayName
      },
      renderCell: (params) => {
        return (
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '4px', alignItems: 'center' }}>
            <Box>
              {params.row.customer?.email ? (
                <Tooltip title={params.row.customer?.email}>
                  <a href={`mailto:${params.row.customer?.email}`} style={{ display: 'flex' }}>
                    <EmailIcon />
                  </a>
                </Tooltip>
              ) : (
                <></>
              )}
            </Box>
            <div>{params.row.customer?.displayName}</div>
          </Box>
        )
      },
    },
    {
      field: 'accountName',
      headerName: 'Account',
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        return params.row.customer?.accountName
      },
    },
    {
      field: 'accountManager',
      headerName: 'Sales Representative',
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        return params.row.customer?.accountManager
      },
    },
    {
      field: 'email',
      headerName: 'E-mail',
      hideable: true,
      align: 'left',
      valueGetter: (params) => {
        return params.row.customer?.email
      },
    },
    {
      field: 'total',
      headerName: 'Total',
      valueGetter: (params) => {
        if (params.row.cancelledAt) {
          return null
        }
        return parseFloat(params.row.netPaymentSet?.shopMoney.amount || '0')
      },
      valueFormatter: ({ value }) => {
        if (value === null) {
          return null
        }
        return usdFormatter.format(value)
      },
      minWidth: 90,
      flex: 1,
      align: 'right',
      headerAlign: 'right',
      type: 'number',
    },
    {
      field: 'quantity',
      headerName: 'Items',
      valueGetter: (params) => {
        if (params.row.cancelledAt) {
          return null
        }
        return params.row.currentSubtotalLineItemsQuantity || 0
      },
      minWidth: 90,
      flex: 0,
      align: 'right',
      headerAlign: 'right',
      type: 'number',
    },
    {
      field: 'deliveryStatus',
      headerName: 'Delivery Status',
      renderCell: (params) => {
        return renderFulfillments(params.row.fulfillments)
      },
      minWidth: 200,
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      type: 'number',
    },
  ]

  const csvOptions: GridCsvExportOptions = {
    allColumns: true,
    fileName: `${currentDateTime()}_BillingReport_${selectedOrganizationIdVar}`,
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} csvOptions={csvOptions} />
      </GridToolbarContainer>
    )
  }

  if (!organizationVar) return <></>
  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ height: '50px' }}>
          <Typography variant={'h5'}>Orders Report </Typography>
        </Box>
        <ReportFilters disabled={loadingShopify} onSubmit={handleRunReport} />
        <Divider />
        <Box sx={{ height: 'calc(100vh - 220px)' }}>
          <Box
            sx={{
              paddingY: '10px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'top',
            }}
          >
            <Typography variant={'h6'}>{organizationVar?.name}</Typography>
            {reportFilters?.dateFilter?.from && reportFilters?.dateFilter?.to && (
              <Typography variant={'body1'}>
                {reportFilters.dateFilter.from.format('MMMM D, YYYY')}
                &nbsp;&ndash;&nbsp;
                {reportFilters.dateFilter.to.format('MMMM D, YYYY')}
              </Typography>
            )}
            <Typography variant={'body1'}>{loadingShopify ? '...' : resultsOrders?.length} Orders</Typography>
          </Box>
          <Box
            sx={{
              height: 'calc(100% - 80px)',
              display: 'grid',
              gridTemplateRows: 'auto 1f auto',
              borderRadius: '16px',
              p: 1,
            }}
          >
            <DataGridPremium
              initialState={{
                aggregation: {
                  model: {
                    quantity: 'sum',
                    total: 'sum',
                  },
                },
              }}
              sx={{
                borderLeftWidth: 0,
                borderRightWidth: 0,
                borderTopWidth: 0,
              }}
              showCellVerticalBorder={false}
              showColumnVerticalBorder={false}
              hideFooterRowCount={true}
              hideFooter={true}
              columnVisibilityModel={{
                email: false,
                cancelledAt: false,
                displayFinancialStatus: false,
                displayWmsStatus: false,
                displayFulfillmentStatus: false,
              }}
              rows={mergedData || []}
              columns={columns}
              columnHeaderHeight={90}
              density={'compact'}
              hideFooterPagination
              scrollEndThreshold={150}
              loading={loadingStores || loadingShopify}
              slots={{
                toolbar: CustomToolbar,
              }}
              disableMultipleRowSelection={true}
            />
          </Box>
        </Box>
      </Box>
    </>
  )
}
