import { useReactiveVar } from '@apollo/client'
import { Box, LinearProgress } from '@mui/material'
import { OrderDetails } from 'pages/ReportsApp/OrderDetails.tsx'
import { useNavigate, useParams } from 'react-router-dom'
import { stores } from 'config/cache.ts'
import { useOrderPageData } from './OrderPage.hooks'

export const OrderPage = () => {
  const navigate = useNavigate()
  const { storeId } = useParams()

  const storesVar = useReactiveVar(stores)
  const storeName = storesVar?.find(store => store.id === storeId);
  if (!storeName) {
    navigate("/vendor/reports/orders")
  }

  const { selectedOrder } = useOrderPageData();

  return (
    <>
      <Box sx={{ paddingLeft: '10px', paddingRight: '10px', paddingTop: '10px', height: '100vh' }}>
        {selectedOrder ? <OrderDetails order={selectedOrder} storeId={storeId} /> : <LinearProgress />}
      </Box>
    </>
  )
}
