import dayjs from 'dayjs'
import PaidIcon from '@mui/icons-material/Paid'
import { Box, Button, Divider, Popover, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import CopyIcon from '@mui/icons-material/ContentPaste'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp'
import _ from 'lodash'
import { ShopifyFulfillment } from 'lib/types.ts'
import WarehouseIcon from '@mui/icons-material/Warehouse'

export const today = dayjs()

export function formatDateTime(v?: Date) {
  return v ? dayjs(v).format('MM/DD/YY hh:mm A') : ''
}

export function formatFormalDateTime(v?: Date | string) {
  return v ? `${dayjs(v).format('MMMM DD, YYYY')} at ${dayjs(v).format('h:mm A')}` : ''
}

export function formatDate(v: Date) {
  return v ? dayjs(v).format('MM/DD/YY') : ''
}

export function formatTime(v: Date) {
  return v ? dayjs(v).format('hh:mm A') : ''
}

export function currentDateTime() {
  return dayjs().format('YYMMDD_HHmm')
}

export const usdFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

export const getOrderFulfillmentStatusColor = (status: string) => {
  if (status === 'FULFILLED') return 'green'
  if (status === 'PARTIALLY_FULFILLED') return 'blue'
  return 'grey'
}

export const getOrderFinancialStatusColor = (status: string) => {
  if (status === 'PAID') return 'green'
  if (status === 'PARTIALLY_REFUNDED') return 'orange'
  return 'grey'
}

export const getOrderWmsStatusColor = (status: string, financialStatus?: string) => {
  if (status === 'WAREHOUSE RECEIVED ORDER') {
    return 'green'
  }
  if (status === 'ERROR SENDING ORDER TO WAREHOUSE') {
    return 'red'
  }
  // if (status && financialStatus === 'PAID') {
  //   return 'orange'
  // }
  return 'grey'
}

export const getShopifyPaymentStatusColor = (status: string) => {
  if (status === 'PAID') return 'green'
  if (status === 'FAILED') return 'red'
  if (status === 'CANCELED') return 'orange'
  if (status === 'IN_TRANSIT') return 'yellow'
  return 'grey'
}

export const renderShopifyPaymentStatus = (status: string) => {
  return (
    <Tooltip title={status}>
      <PaidIcon sx={{ color: getOrderFinancialStatusColor(status) }} />
    </Tooltip>
  )
}

export const renderOrderFinancialStatus = (status: string) => {
  return (
    <Tooltip title={status}>
      <PaidIcon sx={{ color: getOrderFinancialStatusColor(status) }} />
    </Tooltip>
  )
}

export const renderOrderWmsStatus = (status: string, financialStatus?: string) => {
  return (
    <Tooltip title={status}>
      <WarehouseIcon sx={{ color: getOrderWmsStatusColor(status, financialStatus) }} />
    </Tooltip>
  )
}

export const renderOrderFulfillmentStatus = (status: string) => {
  return (
    <Tooltip title={status}>
      <LocalShippingIcon sx={{ fill: getOrderFulfillmentStatusColor(status) }} />
    </Tooltip>
  )
}

const getFulfillmentText = (fulfillments: ShopifyFulfillment[]) => {
  if (fulfillments.map((f) => f.deliveredAt).every((x) => x)) return <>Delivered</>
  if (fulfillments.map((f) => f.deliveredAt).some((x) => x)) {
    return <>Delivered +{fulfillments.length - 1}</>
  }
  if (fulfillments.map((f) => f.deliveredAt).every((x) => !x)) {
    return <>Tracking added</>
  }
  return
}

export const renderFulfillments = (fulfillments: any[]) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [showCaret, setShowCaret] = useState(false)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  if (_.isEmpty(fulfillments)) return

  return (
    <>
      <Button
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={(ev) => handleClick(ev)}
        onMouseEnter={() => setShowCaret(true)}
        onMouseLeave={() => setShowCaret(false)}
        sx={{
          textTransform: 'none',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div>{getFulfillmentText(fulfillments)}</div>
        <div>{showCaret ? <KeyboardArrowDownSharpIcon /> : null}</div>
      </Button>
      <Popover
        id='popover'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        slotProps={{
          paper: {
            style: {
              borderRadius: '10px',
            },
          },
        }}
      >
        <Box
          sx={{ minWidth: '280px', margin: '10px', display: 'flex', flexDirection: 'column', gap: '10px' }}
        >
          {_.sortBy(fulfillments, 'name').map((fulfillment, index) => {
            return (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '7px' }} key={index}>
                <div>
                  {index ? <Divider sx={{ marginBottom: '8px', marginTop: '-5px' }} /> : null}
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div>
                      <Typography variant={'body2'} sx={{ fontWeight: 'bold' }}>
                        {fulfillment.name}
                      </Typography>
                    </div>
                    <div>
                      <Typography variant={'body2'}>
                        {fulfillment.deliveredAt ? 'Delivered' : 'Tracking added'}
                      </Typography>
                    </div>
                  </Box>
                  <div>
                    {fulfillment.trackingInfo.map((track: any, index: number) => {
                      return (
                        <Box
                          key={index}
                          sx={{
                            marginLeft: '20px',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <div>
                              <Typography variant={'body2'}>{track.company} </Typography>
                            </div>
                            <div>
                              <Typography variant={'body2'}>
                                <Button
                                  sx={{ textTransform: 'none' }}
                                  onClick={() => window.open(track.url, '_target')}
                                >
                                  {track.number}
                                </Button>
                              </Typography>
                            </div>
                          </Box>
                          <div>
                            <Tooltip title={'Copy tracking number'}>
                              <Button onClick={() => navigator.clipboard.writeText(track.number)}>
                                <CopyIcon fontSize={'small'} />
                              </Button>
                            </Tooltip>
                          </div>
                        </Box>
                      )
                    })}
                  </div>
                </div>
              </Box>
            )
          })}
        </Box>
      </Popover>
    </>
  )
}

export const adjudicateWmsStatus = (orderWorkflowSteps: any[]) => {
  if (
    ['Success', 'Partial Success'].includes(
      orderWorkflowSteps?.find(({ step }) => step === 'Order Processed')?.status
    )
  ) {
    return 'WAREHOUSE RECEIVED ORDER'
  }
  if (orderWorkflowSteps?.find(({ step }) => step === 'Order Received')?.status === 'Success') {
    return 'ERROR SENDING ORDER TO WAREHOUSE'
  }
  return 'WAREHOUSE AWAITING ORDER'
}
