import { gql } from '@apollo/client'

export const LIST_CUSTOMER_IDS = gql`
  query listCustomerIds(
    $where: CustomerIdWhereInput
    $orderBy: [CustomerIdOrderByInput!]
    $skip: Int
    $take: Int
  ) {
    listCustomerIds(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
      id
      customerId
      privateLabels {
        id
        instanceId
        productName
        archivedReason
      }
    }
  }
`
