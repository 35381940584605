'use client'

import React from 'react'

import { useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  FormControlLabel,
  Paper,
  Snackbar,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Tooltip,
} from '@mui/material'
import {
  Check as CheckIcon,
  Close as CloseIcon,
  ExpandMore as ExpandMoreIcon,
  Search as SearchIcon,
  Sync as SyncIcon,
  FilterList as FilterIcon,
  Archive as ArchiveIcon,
  SyncAlt as MigrateIcon,
} from '@mui/icons-material'
import { useMutation, useQuery, useReactiveVar } from '@apollo/client'
import { PROXY_SHOPIFY_QUERY } from '@/lib/graphql/proxyShopifyQuery'
import { LIST_CUSTOMER_IDS } from '@/lib/graphql/listCustomerIds'
import { selectedStoreId } from 'config/cache'
import { MIGRATE_FROM_SHOPIFY } from '@/lib/graphql/migrateFromShopify'
import { MIGRATE_CUSTOMER_LABELS_FROM_SHOPIFY } from '@/lib/graphql/migrateCustomerLabelsFromShopify'
import { PrivateLabel, ShopifyCustomer } from 'pages/VendorApp/Shop/types.ts'

type MigrationResult = {
  success: boolean
  instanceId: string
  error?: string
  details?: string
}

const getArchiveReasonText = (reason: string): string => {
  switch (reason) {
    case 'ARCHIVED_BY_CUSTOMER_DELETED':
      return 'Customer deleted'
    case 'ARCHIVED_BY_SKU_DELETED':
      return 'SKU deleted'
    case 'ARCHIVED_BY_TEMPLATE_DELETED':
      return 'Template deleted'
    case 'ARCHIVED_BY_INSTANCE_DELETED':
      return 'Instance deleted'
    default:
      return 'Unknown reason'
  }
}

interface Customer {
  id: string
  email: string
  name: string
  privateLabels: PrivateLabel[]
}

const calculateMigrationPercentage = (customer: Customer, dbLabels: PrivateLabel[]) => {
  const totalLabels = customer.privateLabels.length
  if (totalLabels === 0) return 100 // If no labels, consider fully migrated

  const migratedLabels = customer.privateLabels.filter((label) => {
    const matchingLabel = dbLabels
      .concat(...dbLabels)
      .find((l: PrivateLabel) => l.instanceId === label.instanceId)
    return !!matchingLabel
  }).length

  return Math.round((migratedLabels / totalLabels) * 100)
}

export default function CustomerMigrationChecker() {
  const [searchTerm, setSearchTerm] = useState('')
  const [expandedCustomer, setExpandedCustomer] = useState<string | false>(false)
  const [migratingCustomers, setMigratingCustomers] = useState<Record<string, boolean>>({})
  const [migrationResults, setMigrationResults] = useState<Record<string, MigrationResult[]>>({})
  const [showIncompleteOnly, setShowIncompleteOnly] = useState(false)
  const [syncing, setSyncing] = useState(false)
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success' as 'success' | 'error' | 'info' | 'warning',
  })

  // Get all costumers from the database
  const selectedStoreIdVar = useReactiveVar(selectedStoreId)

  const [migrateFromShopify, { loading: migrating }] = useMutation(MIGRATE_FROM_SHOPIFY)
  const [migrateCustomerLabelsFromShopify, { loading: migratingCustomerLabels }] = useMutation(
    MIGRATE_CUSTOMER_LABELS_FROM_SHOPIFY
  )

  const {
    refetch: refetchCustomers,
    data: dataCustomers,
    loading: loadingCustomers,
    error: errorCustomers,
    fetchMore,
  } = useQuery(PROXY_SHOPIFY_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      query: 'customers',
      shopifyStoreId: selectedStoreIdVar,
      variables: JSON.stringify({
        first: 250,
      }),
    },
  })

  const customers: Customer[] =
    dataCustomers?.proxyShopifyQuery?.customers?.data?.map((customer: ShopifyCustomer) => ({
      id: customer.id,
      name: `${customer.firstName} ${customer.lastName}`,
      email: customer.email,
      privateLabels: JSON.parse(customer.privateLabels?.value || '[]'),
    })) || []

  const { data: dataCustomerIds } = useQuery(LIST_CUSTOMER_IDS, {
    skip: customers?.length === 0 || !customers,
    variables: {
      take: 250,
      where: {
        customerId: {
          in: customers.map((customer: Customer) => customer.id),
        },
      },
    },
  })

  const dbLabels =
    dataCustomerIds?.listCustomerIds?.map((customer: ShopifyCustomer) => customer.privateLabels || []) || []

  const filteredCustomers = customers
    .filter((customer: Customer) => {
      // Text search filter
      const matchesSearch =
        customer.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        customer.email?.toLowerCase().includes(searchTerm.toLowerCase())

      // Migration status filter
      if (showIncompleteOnly) {
        const migrationPercentage = calculateMigrationPercentage(customer, dbLabels)
        return matchesSearch && migrationPercentage < 100
      }

      return matchesSearch
    })
    .map((customer) => {
      return {
        ...customer,
        privateLabels: customer.privateLabels.map((label) => {
          return {
            ...label,
            archivedReason: dbLabels
              .concat(...dbLabels)
              .find((l: PrivateLabel) => l.instanceId === label.instanceId)?.archivedReason,
          }
        }),
      }
    })

  // Handle accordion expansion
  const handleAccordionChange =
    (customerId: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedCustomer(isExpanded ? customerId : false)
    }

  // Check if a label has been correctly migrated
  const checkLabelMigration = (instanceId: string) => {
    const matchingLabel = dbLabels
      .concat(...dbLabels)
      .find((label: PrivateLabel) => label.instanceId === instanceId)
    return !!matchingLabel
  }

  // Get color for migration status chip
  const getMigrationStatusColor = (percentage: number) => {
    if (percentage === 100) return 'success'
    if (percentage >= 50) return 'warning'
    return 'error'
  }

  const handleSnackbarClose = () => {
    setSnackbar((prev) => ({ ...prev, open: false }))
  }

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowIncompleteOnly(event.target.checked)
  }

  const handleSyncStore = () => {
    setSyncing(true)
    migrateFromShopify({
      variables: {
        shopifyStoreConfigurationId: selectedStoreIdVar,
      },
    })
    setSyncing(false)
    setSnackbar({
      open: true,
      message: 'Store synced successfully',
      severity: 'success',
    })
  }

  const hasUnmigratedLabels = (customer: Customer) => {
    return customer.privateLabels.some((label: PrivateLabel) => {
      const matchingLabel = dbLabels
        .concat(...dbLabels)
        .find((l: PrivateLabel) => l.instanceId === label.instanceId)
      return !matchingLabel
    })
  }

  const handleMigrateCustomerLabels = async (customer: Customer) => {
    // Set migrating state for this customer
    setMigratingCustomers((prev) => ({ ...prev, [customer.id]: true }))
    await migrateCustomerLabelsFromShopify({
      variables: {
        shopifyStoreConfigurationId: selectedStoreIdVar,
        customerId: customer.id,
      },
      onCompleted: (data) => {
        setMigratingCustomers((prev) => ({ ...prev, [customer.id]: false }))
        setMigrationResults((prev) => ({
          ...prev,
          [customer.id]: data.migrateCustomerLabelsFromShopify,
        }))
        setSnackbar({
          open: true,
          message: 'Labels migrated successfully',
          severity: 'success',
        })
      },
      onError: (error) => {
        console.error('Error migrating labels: ', error)
        setMigratingCustomers((prev) => ({ ...prev, [customer.id]: false }))
        setSnackbar({
          open: true,
          message: 'Error migrating labels',
          severity: 'error',
        })
      },
    })
  }

  return (
    <Card sx={{ maxWidth: 1200, mx: 'auto', my: 2 }}>
      <CardHeader
        title='Customer Label Migration Checker'
        subheader='Verify if customer labels have been correctly migrated between systems'
        action={
          <Button
            variant='contained'
            color='primary'
            startIcon={syncing ? <CircularProgress size={20} color='inherit' /> : <SyncIcon />}
            onClick={handleSyncStore}
            disabled={syncing}
            sx={{ mt: 1 }}
          >
            {syncing ? 'Syncing...' : 'Sync Store'}
          </Button>
        }
      />
      <CardContent>
        <Box sx={{ mb: 3 }}>
          <TextField
            placeholder='Search customers...'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            variant='outlined'
            size='small'
            sx={{ maxWidth: 400 }}
            InputProps={{
              startAdornment: <SearchIcon sx={{ mr: 1, color: 'text.secondary' }} />,
            }}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            bgcolor: showIncompleteOnly ? 'action.selected' : 'transparent',
            borderRadius: 1,
            px: showIncompleteOnly ? 1 : 0,
          }}
        >
          <Tooltip title='Show only customers with incomplete migrations'>
            <FormControlLabel
              control={<Switch checked={showIncompleteOnly} onChange={handleFilterChange} color='primary' />}
              label={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <FilterIcon sx={{ mr: 0.5, fontSize: '1rem' }} />
                  <Typography variant='body2'>Show incomplete only</Typography>
                </Box>
              }
            />
          </Tooltip>
        </Box>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Customer</TableCell>
                <TableCell>Email</TableCell>
                <TableCell align='right'>Labels</TableCell>
                <TableCell align='right'>Migration Status</TableCell>
                <TableCell align='right'>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredCustomers.map((customer) => {
                // Calculate migration status
                const totalLabels = customer.privateLabels.length || 0
                const migratedLabels =
                  customer.privateLabels.filter((label) => checkLabelMigration(label.instanceId)).length || 0
                const needsMigration = hasUnmigratedLabels(customer)
                const migrationPercentage =
                  totalLabels > 0 ? Math.round((migratedLabels / totalLabels) * 100) : 100
                const isCustomerMigrating = migratingCustomers[customer.id] || false

                return (
                  <React.Fragment key={customer.id}>
                    <TableRow>
                      <TableCell component='th' scope='row'>
                        {customer.name}
                      </TableCell>
                      <TableCell>{customer.email}</TableCell>
                      <TableCell align='right'>{customer?.privateLabels.length}</TableCell>
                      <TableCell align='right'>
                        <Chip
                          label={`${migrationPercentage}% Migrated`}
                          color={getMigrationStatusColor(migrationPercentage)}
                          size='small'
                        />
                      </TableCell>
                      <TableCell align='right'>
                        {needsMigration && (
                          <Button
                            variant='outlined'
                            color='primary'
                            size='small'
                            startIcon={
                              isCustomerMigrating ? (
                                <CircularProgress size={16} color='inherit' />
                              ) : (
                                <MigrateIcon fontSize='small' />
                              )
                            }
                            onClick={() => handleMigrateCustomerLabels(customer)}
                            disabled={isCustomerMigrating || syncing}
                          >
                            {isCustomerMigrating ? 'Migrating...' : 'Migrate Labels'}
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                        <Accordion
                          expanded={expandedCustomer === customer.id}
                          onChange={handleAccordionChange(customer.id)}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel-${customer.id}-content`}
                            id={`panel-${customer.id}-header`}
                          >
                            <Typography variant='subtitle2'>Label Migration Details</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <TableContainer>
                              <Table size='small'>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Label Name</TableCell>
                                    <TableCell>Instance ID</TableCell>
                                    <TableCell>Migration Status</TableCell>
                                    <TableCell>Archive Status</TableCell>
                                    <TableCell>Details</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {customer.privateLabels.map((label: PrivateLabel) => {
                                    const isMigrated = checkLabelMigration(label.instanceId)
                                    const migrationResult = migrationResults[customer.id]?.find(
                                      (result) => result.instanceId === label.instanceId
                                    )
                                    
                                    console.log("migrationResult: ", migrationResult?.error )
                                    return (
                                      <TableRow key={label.instanceId}>
                                        <TableCell>{label.productName}</TableCell>
                                        <TableCell>
                                          <Box
                                            component='code'
                                            sx={{
                                              bgcolor: 'grey.100',
                                              p: 0.5,
                                              borderRadius: 1,
                                              fontSize: '0.75rem',
                                            }}
                                          >
                                            {label.instanceId}
                                          </Box>
                                        </TableCell>
                                        <TableCell>
                                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            {isMigrated ? (
                                              <>
                                                <CheckIcon
                                                  color='success'
                                                  fontSize='small'
                                                  sx={{ mr: 0.5 }}
                                                />
                                                <Typography variant='body2'>Migrated</Typography>
                                              </>
                                            ) : (
                                              <>
                                                <CloseIcon color='error' fontSize='small' sx={{ mr: 0.5 }} />
                                                <Typography variant='body2'>Not Migrated</Typography>
                                              </>
                                            )}
                                          </Box>
                                        </TableCell>
                                        <TableCell>
                                          {label.archivedReason ? (
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                              <ArchiveIcon
                                                color='warning'
                                                fontSize='small'
                                                sx={{ mr: 0.5 }}
                                              />
                                              <Tooltip title={label.archivedReason}>
                                                <Typography variant='body2'>
                                                  Archived: {getArchiveReasonText(label.archivedReason!)}
                                                </Typography>
                                              </Tooltip>
                                            </Box>
                                          ) : (
                                            <Typography variant='body2'>Active</Typography>
                                          )}
                                        </TableCell>
                                        <TableCell>
                                          <Typography variant='body2' color='text.secondary'>
                                            {migrationResult?.error}
                                          </Typography>
                                        </TableCell>
                                      </TableRow>
                                    )
                                  })}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </AccordionDetails>
                        </Accordion>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                )
              })}
              {filteredCustomers.length === 0 && (
                <TableRow>
                  <TableCell colSpan={4} align='center' sx={{ py: 3 }}>
                    <Typography color='text.secondary'>
                      No customers found matching your search criteria
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          variant='filled'
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Card>
  )
}
