import { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { PROXY_SHOPIFY_QUERY } from 'lib/graphql/proxyShopifyQuery.ts'

export const useOrderPageData = () => {
  const { storeId, orderId } = useParams()
  const [selectedOrder, setSelectedOrder] = useState()
  const [lazyShopifyQuery, { loading: loadingShopify }] = useLazyQuery(PROXY_SHOPIFY_QUERY)

  useEffect(() => {
    if (!storeId || !orderId) {
      setSelectedOrder(undefined)
    } else {
      lazyShopifyQuery({
        fetchPolicy: 'no-cache',
        variables: {
          query: 'order',
          shopifyStoreId: storeId,
          variables: JSON.stringify({
            id: `gid://shopify/Order/${orderId}`,
          }),
        },
        onCompleted: (data) => {
          setSelectedOrder(JSON.parse(data.proxyShopifyQuery || '{}')?.data?.order)
        },
      })
    }
  }, [storeId, orderId])

  return { selectedOrder, loadingShopify }
}
